// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/apps/notification/store/reducer'
import appVersion from '@src/views/apps/app-version/store/reducer'
import invoice from '@src/views/apps/product/store/reducer'

const rootReducer = combineReducers({
  auth,
  users,
  navbar,
  layout,
  invoice,
  appVersion
})

export default rootReducer
